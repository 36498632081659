import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {apiGetGlobalSeasonConfig} from "./api/requests";
import {Loading} from "./common/utility/loading";
import {delay} from "./common/utility/delay";
import MapListElement from "./MapListElement";
import MapsTabsElement from "./MapsTabsElement";
import IconTitleValue from "./common/iconTitleValue";
import CountdownLabel from "./common/countdownLabel";
import SeasonPassPanel from "./SeasonPassPanel";
import AdminModalAuthPin from "./admin/AdminModalAuthPin";
import ModalHowToPlay from "./ModalHowToPlay";
import MapSeasonTokenHeroesPanel from "./MapSeasonTokenHeroesPanel";
import MapSeasonTokenHeroesMainPagePanel from "./MapSeasonTokenHeroesMainPagePanel";

class MainPage extends Component {
    constructor(props) {
        super(props);
        //console.log("MainPage props", this.props)
        this.state = {
            loading: true,
        }
        this.loadGlobalConfig = this.loadGlobalConfig.bind(this);
    }

    componentDidMount() {
        this.loadGlobalConfig()
    }

    async loadGlobalConfig() {
        await apiGetGlobalSeasonConfig({

        }).then(async (response) => {
            await delay(1000);
            let currentSeason = null;
            for(let i = 0; i < response.data.seasons.length; i++) {
                let dateStart = Date.parse(response.data.seasons[i].config.date_start);
                const dateEnd = Date.parse(response.data.seasons[i].config.date_end);

                if(response.data.seasons[i].config.hero_shards === 0) {
                    continue
                }

                if(response.data.seasons[i].config.hero_shards === 1) {
                    dateStart = Date.parse(response.data.seasons[i].config.hero_shards_start_sale);
                }

                if( Date.now() >= dateStart && Date.now() <= dateEnd) {
                    currentSeason = response.data.seasons[i];
                    break
                }
            }
            this.setState({
                currentSeason: currentSeason,
                grand_season : response.data.grand_season,
                seasons: response.data.seasons,
                campaigns: response.data.campaigns,
                campaigns2: response.data.campaigns2,
                campaigns3: response.data.campaigns3,
                loading: false,
            })
        }).catch(e => {
            console.log(e)
            window.alerts.alert("internal error")
        })
    }

    render() {

            if(this.state.loading === true) {
                return <>
                    <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                        <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                            <h1 className={"notransform-h1"}>Loading data.</h1>
                        </div>
                        <div>
                            <p>Welcome to Force Prime Heroes, a speculative fully onchain strategy game. Earn Season Points by playing on tournament maps to claim more rewards.</p>
                        </div>
                        <Loading></Loading>
                    </div>
                    <a id={"maps"}></a>
                </>

            }
            if(this.state.loading === false && this.state.grand_season === null) {
                return <>
                     We are currently doing an update, please try refreshing in 5 minutes!
                </>
            }

            let showCampaign1 = false;
            let showCampaign2 = false;
            let showCampaign3 = false;

            if (this.state.campaigns.length > 0) {
                showCampaign1 = true;
            }
            if (this.state.campaigns2.length > 0) {
                showCampaign2 = true;
            }
            if (this.state.campaigns3.length > 0) {
                showCampaign3 = true;
            }

        return (
            <>
                <MapsTabsElement currentSeason={this.state.currentSeason} />
                {this.state.currentSeason !== null ?
                    <MapSeasonTokenHeroesMainPagePanel
                        mapId={this.state.currentSeason.config.id}
                    />
                    : <></>}
                <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <a id={"maps-campaign1"}></a>
                    {showCampaign1 ? <>
                        <div className={"panel-row width100 "} style={{justifyContent: "space-between"}}>
                            <h1 className={"notransform-h1"}>Tutorial Campaign</h1>
                        </div>
                        <div>
                            <p>Gather gold from chests and mines, build up your army by recruiting units at towers, and defeat the final boss - the Bone Dragon - to claim victory.</p>
                            <ModalHowToPlay label={"How To Play"} classNameType={"a-pointer"}/>
                        </div>
                    </> : <></>}


                    {this.state.campaigns.map((v, k) => {
                        if (v.config.campaign !== 1)
                            return <></>
                        return <MapListElement key={"map-k-" + v.config.id} config={v.config} mapType={"campaign"}/>
                    })}
                    <a id={"maps-campaign2"}></a>

                    {showCampaign2 ? <>
                        <div className="pt-4  panel-row width100" style={{justifyContent: "space-between"}}>
                            <h1 className={"notransform-h1"}>Campaign 2. Portals</h1>
                        </div>

                        <div>
                            <p>Pass through the magical portal and explore the hidden corners of the Force Prime
                                world.</p>
                        </div>
                    </> : <></>}

                    {this.state.campaigns2.map((v, k) => {
                        if (v.config.campaign !== 1)
                            return <></>
                        return <MapListElement key={"map-k2-" + v.config.id} config={v.config} mapType={"campaign"}/>
                    })}
                    <a id={"maps-campaign3"}></a>

                    {showCampaign3 ? <>
                        <div className="pt-4  panel-row width100" style={{justifyContent: "space-between"}}>
                            <h1 className={"notransform-h1"}>Campaign 2.1. EthCC</h1>
                        </div>

                        <div>
                            <p>These maps were played in the Force Prime Heroes Grand Prix Finals at EthCC in Brussels
                                on July 11th. Now you can also check your skills on them.</p>
                        </div>
                    </> : <></>}

                    {this.state.campaigns3.map((v, k) => {
                        if (v.config.campaign !== 1)
                            return <></>
                        return <MapListElement key={"map-k3-" + v.config.id} config={v.config} mapType={"campaign"}/>
                    })}
                    <a id={"maps-season"}></a>
                    <div className="pt-4 panel-row width100" style={{justifyContent: "space-between"}}>
                        <h1 className={"notransform-h1"}>Speculative Tournaments</h1>
                    </div>

                    {this.state.seasons.map((v, k) => {
                        if (v.config.campaign === 1)
                            return <></>
                        return <MapListElement key={"map-k4-" + v.config.id} config={v.config} mapType={"season"}/>
                    })}

                </div>
            </>
        )
    }
}

export default MainPage;
